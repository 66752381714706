<template>
  <div>
    <Header ref="header"/>
    <div class="homeindex">
      <div class="content" style="min-height: 950px;">
        <div class="visual-item" style="display: block;">
          <div class="view">
            <div class="banner home-banner">
              <swiper ref="mySwiper" :options="swiperOptions" >
                <swiper-slide v-for="(banner,index) in banners" v-bind:key="index">
                  <div :style="{background:'url('+banner.img+') center center no-repeat'}" style="height: 500px" v-on:click="toBanner(banner.url)">
                  </div>
<!--                  <img :src="banner"/>-->
                </swiper-slide>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination" slot="pagination"></div>
                <!-- 如果需要导航按钮 -->
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>

<!--              <div class="bd">-->
<!--                <ul data-type="range" data-slide="fold" style="position: relative; width: 1903px; height: 500px;">-->
<!--                  <li style="background-image: url(&quot;http://admin.jzybyy.com/static/banner/001.png&quot;); background-position: center center; background-repeat: no-repeat; position: absolute; width: 1903px; left: 0px; top: 0px; display: none;">-->
<!--                    <div class="banner-width"><a href="" target="_blank" style="height:500px;"></a></div>-->
<!--                  </li>-->
<!--                  <li style="background-image: url(&quot;http://admin.jzybyy.com/static/banner/001.png&quot;); background-position: center center; background-repeat: no-repeat; position: absolute; width: 1903px; left: 0px; top: 0px; display: list-item;">-->
<!--                    <div class="banner-width"><a href="" target="_blank" style="height:500px;"></a></div>-->
<!--                  </li>-->
<!--                  <li style="background-image: url(&quot;http://admin.jzybyy.com/static/banner/001.png&quot;); background-position: center center; background-repeat: no-repeat; position: absolute; width: 1903px; left: 0px; top: 0px; display: none;">-->
<!--                    <div class="banner-width"><a href="" target="_blank" style="height:500px;"></a></div>-->
<!--                  </li>-->
<!--                </ul>-->
<!--                <div class="spec" data-spec=""></div>-->
<!--              </div>-->
<!--              <div class="hd">-->
<!--                <ul>-->
<!--                  <li class="">1</li>-->
<!--                  <li class="on">2</li>-->
<!--                  <li class="">3</li>-->
<!--                </ul>-->
<!--              </div>-->
              <div class="vip-outcon">
                <div class="vip-con">
                  <div class="insertVipEdit" data-mode="insertVipEdit">
                    <div ectype="user_info">
                      <div class="avatar">
                        <router-link :to="{path:'member'}" v-if="!!userInfo.userName">
                          <img src="@/assets/images/touxiang.jpg">
                        </router-link>
                        <img src="@/assets/images/avatar.png" v-if="!userInfo.userName">
                      </div>
                      <div class="login-info" v-if="!userInfo.userName">
                        <span>欢迎来到 {{webConfig.platformName}}！</span>
                        <router-link :to="{path:'login'}" target="_blank" tag="a" class="login-button login-success">
                          请登录
                        </router-link>
                      </div>
                      <div class="login-info" v-if="!!userInfo.userName">
                        <span>Hi，{{ userInfo.trueName }}</span>
                        <router-link :to="{path:'member'}" target="_blank" tag="a" class="login-button login-success">
                          进入会员中心
                        </router-link>
                      </div>
                    </div>
                    <div class="vip-item">
                      <div class="tit">
                        <a href="javascript:void(0);" class="tab_head_item on">召回通知</a>
                      </div>
                      <div class="con">
                        <ul>
                          <li v-for="(article,index) in articles" v-bind:key="index">
                            <router-link :to="{path:'/article/view',query:{id:article.id}}" target="_blank" tag="a">
                              <span>{{article.title}}</span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
<!--                    <div class="vip-item">-->
<!--                      <div class="tit">快捷入口</div>-->
<!--                      <div class="kj_con">-->
<!--                        <div class="item item_1">-->
<!--                          <a href="javascript:void(0);">-->
<!--                            <i class="iconfont icon-browse"></i>-->
<!--                            <span>我的浏览</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="item item_2">-->
<!--                          <a href="javascript:void(0);">-->
<!--                            <i class="iconfont icon-zan-alt"></i>-->
<!--                            <span>我的收藏</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="item item_3">-->
<!--                          <a href="javascript:void(0);">-->
<!--                            <i class="iconfont icon-order"></i>-->
<!--                            <span>我的订单</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="item item_4">-->
<!--                          <a href="javascript:void(0);">-->
<!--                            <i class="iconfont icon-password-alt"></i>-->
<!--                            <span>账号安全</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="item item_5">-->
<!--                          <a href="javascript:void(0);">-->
<!--                            <i class="iconfont icon-share-alt"></i>-->
<!--                            <span>我要分享</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                        <div class="item item_6">-->
<!--                          <a href="javascript:void(0);">-->
<!--                            <i class="iconfont icon-settled"></i>-->
<!--                            <span>商家入驻</span>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--
        <div class="visual-item w1200" style="display: block;">
          <div class="view">
            <div class="floor-content" data-type="range">
              <div class="floor-line-con floorEight floor-color-type-1">
                <div class="floor-hd">
                  <div class="hd-tit">政策优品</div>
                </div>
                <div class="floor-bd FE-bd-more-02" v-loading="PromotionLoading" element-loading-text="拼命加载中......" style="height: 432px;">
                  <div>
                    <router-link :to="{path:'goods',query:{id:'7000002'}}" target="_blank" tag="a">
                      <img src="http://image.yabyy.com/home/index/001.jpg" width="1200px"/>
                    </router-link>
                  </div>
                  <div>
                    <router-link v-if="PromotionBanner[0].search!=''" :to="{path:'search',query:{keywords:PromotionBanner[0].search}}" target="_blank" tag="a">
                      <img :src="PromotionBanner[0].url" width="600px"/>
                    </router-link>
                    <router-link v-if="PromotionBanner[1].search!=''" :to="{path:'search',query:{keywords:PromotionBanner[1].search}}" target="_blank" tag="a">
                      <img :src="PromotionBanner[1].url" width="600px"/>
                    </router-link>
                    <router-link v-if="PromotionBanner[0].id!=''" :to="{path:'goods',query:{id:PromotionBanner[0].id}}" target="_blank" tag="a">
                      <img :src="PromotionBanner[0].url" width="600px"/>
                    </router-link>
                    <router-link v-if="PromotionBanner[1].id!=''" :to="{path:'goods',query:{id:PromotionBanner[1].id}}" target="_blank" tag="a">
                      <img :src="PromotionBanner[1].url" width="600px"/>
                    </router-link>
                  </div>
                  <div class="bd-left">
                    <div class="floor_silder floor_silder1">
                      <router-link v-if="PromotionFloor[0].id!=''" :to="{path:'goods',query:{id:PromotionFloor[0].id}}" target="_blank" tag="a">
                        <img :src="PromotionFloor[0].url" width="232" height="430">
                      </router-link>
                      <router-link v-if="PromotionFloor[0].search!=''" :to="{path:'search',query:{keywords:PromotionFloor[0].search}}" target="_blank" tag="a">
                        <img :src="PromotionFloor[0].url" width="232" height="430">
                      </router-link>
                      <img v-else :src="PromotionFloor[0].url" width="232" height="430">
                    </div>
                  </div>

                  <div class="bd-right">
                    <ul class="p-list" ectype="pList">
                      <li class="child-double opacity_img">
                        <div class="floor-left-adv">
                          <router-link v-if="PromotionFloor[1].id!=''" :to="{path:'goods',query:{id:PromotionFloor[1].id}}" target="_blank" tag="a">
                            <img :src="PromotionFloor[1].url" width="400" height="236">
                          </router-link>
                          <router-link v-else-if="PromotionFloor[1].search!=''" :to="{path:'search',query:{keywords:PromotionFloor[1].search}}" target="_blank" tag="a">
                            <img :src="PromotionFloor[1].url" width="400" height="236">
                          </router-link>
                          <img v-else :src="PromotionFloor[1].url" width="400" height="236">
                        </div>
                      </li>

                      <li class="li opacity_img" v-for="(good,index) in PromotionFloor.slice(2)" v-bind:key="index">
                        <router-link v-if="good.id!=''" :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                          <img :src="good.url">
                        </router-link>
                        <router-link v-else-if="good.search!=''" :to="{path:'search',query:{keywords:good.search}}" target="_blank" tag="a">
                          <img :src="good.url">
                        </router-link>
                        <img v-else :src="good.url">
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
              <div class="spec" data-spec="" data-title="undefined"></div></div>
          </div>
        </div>
        -->

        <!--
        <div class="visual-item w1200" style="display: block; position: relative; opacity: 1; left: 0px; top: 0px;">
          <div class="view">
            <div class="floor-content">
              <div class="floor-line-con floorFour floor-color-type-18">
                <div class="floor-hd">
                  <div class="hd-tit">药品精选专区</div>
                </div>
                <div class="floor-bd FF-bd-more-04" style="height: 600px" v-loading="WesternMedicineLoading" element-loading-text="拼命加载中......">
                  <div class="bd-right">
                    <div class="floor-tabs-content clearfix">
                      <div class="f-r-main f-r-m-adv" style="display: block;">
                        <ul class="p-list">
                          <li v-for="(brand,index) in WesternMedicineFloor" v-bind:key="index" class="opacity_img" style="width: 300px;height: 200px;">
                            <router-link v-if="brand.id!=''" :to="{path:'goods',query:{id:brand.id}}" target="_blank">
                              <img :src="brand.url" width="300" height="200">
                            </router-link>
                            <router-link v-else-if="brand.search!=''" :to="{path:'search',query:{special:brand.search}}" target="_blank">
                              <img :src="brand.url" width="300" height="200">
                            </router-link>
                            <img v-else :src="brand.url" width="300" height="200">
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        -->

        <!--
        <div class="visual-item w1200" style="display: block; position: relative; opacity: 1; left: 0px; top: 0px;">
          <div class="view">
            <div class="floor-content">
              <div class="floor-line-con floorFour floor-color-type-18">
                <div class="floor-hd">
                  <div class="hd-tit">药品精选专区</div>
                </div>
                <div class="floor-bd FF-bd-more-04" v-loading="WesternMedicineLoading" element-loading-text="拼命加载中......">
                  <div class="bd-right">
                    <div class="floor-tabs-content clearfix">
                      <div class="f-r-main f-r-m-adv" style="display: block;">
                        <ul class="p-list">
                          <li v-for="(good,index) in WesternMedicineGoods" v-bind:key="index" class="opacity_img">
                            <div class="product">
                              <div class="p-img">
                                <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank">
                                  <img :src="good.image" width="140" height="140"/>
                                </router-link>
                              </div>
                              <div class="p-name">
                                <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank">
                                  {{ good.name }}
                                </router-link>
                              </div>
                              <div class="p-price">
                                <span class="shop-price" v-if="isLogin">
                                  <em>¥</em>{{good.price}}
                                </span>
                                <span v-else>
                                  <router-link :to="{path:'/login'}">
                                    <em>¥</em>价格登录后可见
                                  </router-link>
                                </span>
                                <span class="original-price">
                                  <em>¥</em>{{good.marketPrice}}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="floor-fd">
                  <div class="floor-fd-brand clearfix">
                    <div class="item" v-for="(brand,index) in WesternMedicineFloor.brand" v-bind:key="index">
                      <router-link :to="{path:'search',query:{keywords:brand.search}}" target="_blank">
                        <div class="link-l"></div>
                        <div class="img"><img :src="brand.url" :title="brand.search"></div>
                        <div class="link"></div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        -->

        <!--
        <div class="visual-item w1200" style="display: block; position: relative; opacity: 1; left: 0px; top: 0px;">
          <div class="view">
            <div class="floor-content">
              <div class="floor-line-con floorFour floor-color-type-18 health">
                <div class="floor-hd">
                  <div class="hd-tit">保健精选品牌</div>
                </div>
                <div class="floor-bd FF-bd-more-04" v-loading="HealthLoading" element-loading-text="拼命加载中......">
                  <div class="bd-right">
                    <div class="floor-tabs-content clearfix">
                      <div class="f-r-main f-r-m-adv" style="display: block;">
                        <ul class="p-list">
                          <li v-for="(brand,index) in HealthFloor" v-bind:key="index" class="opacity_img">
                            <router-link v-if="brand.id!=''" :to="{path:'goods',query:{id:brand.id}}" target="_blank">
                              <img :src="brand.url" width="200" height="200">
                            </router-link>
                            <router-link v-else-if="brand.search!=''" :to="{path:'search',query:{keywords:brand.search}}" target="_blank">
                              <img :src="brand.url" width="200" height="200">
                            </router-link>
                            <img v-else :src="brand.url" width="200" height="200">
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        -->

        <!--
        <div class="visual-item w1200" style="display: block; position: relative; opacity: 1; left: 0px; top: 0px;">
          <div class="view">
            <div class="floor-content">
              <div class="floor-line-con floorFour floor-color-type-18">
                <div class="floor-hd">
                  <div class="hd-tit">中药精选专区</div>
                </div>
                <div class="floor-bd FF-bd-more-04" style="height: 200px" v-loading="ChineseMedicineLoading" element-loading-text="拼命加载中......">
                  <div class="bd-right">
                    <div class="floor-tabs-content clearfix">
                      <div class="f-r-main f-r-m-adv" style="display: block;">
                        <ul class="p-list">
                          <li v-for="(brand,index) in ChineseMedicineFloor" v-bind:key="index" class="opacity_img" style="width: 300px;height: 200px;">
                            <router-link v-if="brand.id!=''" :to="{path:'goods',query:{id:brand.id}}" target="_blank">
                              <img :src="brand.url" width="300" height="200">
                            </router-link>
                            <router-link v-else-if="brand.search!=''" :to="{path:'search',query:{keywords:brand.search}}" target="_blank">
                              <img :src="brand.url" width="300" height="200">
                            </router-link>
                            <img v-else :src="brand.url" width="300" height="200">
                          </li>
                          <li v-for="(good,index) in ChineseMedicineGoods" v-bind:key="index" class="opacity_img">
                            <div class="product">
                              <div class="p-img">
                                <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank">
                                  <img :src="good.image" width="140" height="140">
                                </router-link>
                              </div>
                              <div class="p-name">
                                <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank">
                                  {{ good.name }}
                                </router-link>
                              </div>
                              <div class="p-price">
                                <span class="shop-price" v-if="isLogin">
                                  <em>¥</em>{{good.price}}
                                </span>
                                <span v-else>
                                  <router-link :to="{path:'/login'}">
                                    <em>¥</em>价格登录后可见
                                  </router-link>
                                </span>
                                <span class="original-price">
                                  <em>¥</em>{{good.mktprice}}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        -->

        <div class="visual-item w1200" style="display: block;">

          <div class="view">
            <div class="lift-channel clearfix" id="guessYouLike" data-type="range" data-lift="家用电器">
              <div data-goodstitle="title"><div class="ftit"><h3>随便逛逛</h3></div></div>
              <ul>

                <li class="opacity_img" v-for="(good, index) in PromotionGoods" :key="index">
                  <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="getCoverImage(good.images)"></div>
                    <div class="p-name">{{good.name}}</div>
                    <div class="p-price">
                      <div class="shop-price">
                        <em>¥</em>{{good.price}}
                      </div>
                      <div class="original-price"><em>¥</em>{{good.marketPrice}}</div>
                    </div>
                  </router-link>
                </li>
              </ul>
              <div class="spec" data-spec=""></div>
            </div>
          </div>
        </div>

      </div>
    </div>

<!--    <div class="w w1200" style="margin-top: 80px;">-->
<!--      <router-link :to="{path:'/goods',query:{id:'7000003'}}" target="_blank" tag="a">-->
<!--        <img src="http://image.yabyy.com/home/banner/banner_000000.jpg" style="width: 1200px">-->
<!--      </router-link>-->
<!--    </div>-->

    <Footer/>
  </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { getGoodsSearch } from '@/api/good'
import { getArticlePaging } from "@/api/article";
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import {mapGetters} from 'vuex'

export default {
  name: "Index",
  components: {
    Header,
    Footer,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      userInfo: {},
      swiperOptions: {
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        autoplay:true
      },

      articles:[],

      banners:[
        //{'img':'http://image.yabyy.com/home/banner/banner_000048.png','url':''},
        //{'img':'http://image.yabyy.com/home/banner/banner_000047.png','url':''},
        //{'img':'http://image.yabyy.com/home/banner/banner_000046.png','url':'/jxy'},
        {'img':'http://image.yabyy.com/home/banner/banner_000044.png','url':''},

        //{'img':'http://image.yabyy.com/home/banner/banner_000023.png','url':''},
      ],
      // 政策优品模块np
      PromotionBanner:[
        {'url':'http://image.yabyy.com/home/floor/promotion/1000778.png',id:'4629',search:''}, //4629	1000778	酒石酸美托洛尔片
        {'url':'http://image.yabyy.com/home/floor/promotion/1007997.png',id:'17343',search:''},//17343	1007997	酒石酸美托洛尔片
      ],

      PromotionFloor:[
        {'url':'http://image.yabyy.com/home/floor/promotion/1011471.png',id:'1011471',search:''},
        {'url':'http://image.yabyy.com/home/floor/promotion/1003214.png',id:'1003214',search:''},
        {'url':'http://image.yabyy.com/home/floor/promotion/1006001.png',id:'1006001',search:''},
        {'url':'http://image.yabyy.com/home/floor/promotion/1006755.png',id:'1006755',search:''},
        {'url':'http://image.yabyy.com/home/floor/promotion/1010513.png',id:'1010513',search:''},
        {'url':'http://image.yabyy.com/home/floor/promotion/1010823.png',id:'1010823',search:''},
        {'url':'http://image.yabyy.com/home/floor/promotion/1011809.png',id:'1011809',search:''},
        {'url':'http://image.yabyy.com/home/floor/promotion/1037862.png',id:'1037862',search:''},
      ],
      PromotionLoading:false,
      PromotionGoods:[],
      // 保健精选品牌
      HealthFloor:[
        {'url':'http://image.yabyy.com/home/floor/health/001.png','id':'','search':'鸿茅'},
        {'url':'http://image.yabyy.com/home/floor/health/002.png','id':'','search':'鱼跃'},
        {'url':'http://image.yabyy.com/home/floor/health/003.png','id':'','search':'养无极'},
        {'url':'http://image.yabyy.com/home/floor/health/004.png','id':'','search':'青岛海诺'},
        {'url':'http://image.yabyy.com/home/floor/health/005.png','id':'','search':'三诺生物'},
        {'url':'http://image.yabyy.com/home/floor/health/006.png','id':'','search':'杜蕾斯'},
        {'url':'http://image.yabyy.com/home/floor/health/007.png','id':'','search':'紫竹'},
        {'url':'http://image.yabyy.com/home/floor/health/008.png','id':'','search':'碧生源'},
        {'url':'http://image.yabyy.com/home/floor/health/009.png','id':'','search':'合生元'},
        {'url':'http://image.yabyy.com/home/floor/health/010.png','id':'','search':'康洲'},
        {'url':'http://image.yabyy.com/home/floor/health/011.png','id':'','search':'六神'},
        {'url':'http://image.yabyy.com/home/floor/health/012.png','id':'','search':'云南白药'},
      ],
      HealthLoading:false,
      //中药精选专区
      ChineseMedicineFloor:[
        // ids:'1000551,1000552,1006372,1006407,1009747,1010971,1012214,1013221,1031476,1031518,1031519,1032440'
        {'url':'http://image.yabyy.com/home/floor/zhongyao/001.png','id':'','search':'天灵'},
        {'url':'http://image.yabyy.com/home/floor/zhongyao/002.png','id':'','search':'宜草'},
        {'url':'http://image.yabyy.com/home/floor/zhongyao/003.png','id':'','search':'永刚'},
        {'url':'http://image.yabyy.com/home/floor/zhongyao/004.png','id':'','search':'真仁堂'},
      ],
      ChineseMedicineLoading:false,
      ChineseMedicineGoods:[],
      //西药精选专区
      WesternMedicineFloor:[
        {'url':'http://image.yabyy.com/home/floor/xiyao/001.png','id':'','search':'三九'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/002.png','id':'','search':'拜耳'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/003.png','id':'','search':'葵花'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/004.png','id':'','search':'思密达'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/005.png','id':'','search':'国药致君'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/006.png','id':'','search':'和黄'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/007.png','id':'','search':'济川'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/008.png','id':'','search':'金活'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/009.png','id':'','search':'万通'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/010.png','id':'','search':'滇虹'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/011.png','id':'','search':'白云山'},
        {'url':'http://image.yabyy.com/home/floor/xiyao/012.png','id':'','search':'韩美'},
        // ids:'1001663,1002047,1002250,1002300,1004718,1005050,1005695,1005846,1007547,1010027,1034655,1007997',
        // brand:[
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/001.png','search':'拜耳'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/002.png','search':'和黄'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/003.png','search':'济川'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/004.png','search':'金活'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/005.png','search':'康王'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/006.png','search':'葵花'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/007.png','search':'三九'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/008.png','search':'思密达'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/009.png','search':'万通'},
        //   {'url':'http://image.yabyy.com/home/floor/westernmedicine/010.png','search':'致君'}
        // ]
      ],
      // WesternMedicineFloor:{
      //   ids:'1001663,1002047,1002250,1002300,1004718,1005050,1005695,1005846,1007547,1010027,1034655,1007997',
      //   brand:[
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/001.png','search':'拜耳'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/002.png','search':'和黄'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/003.png','search':'济川'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/004.png','search':'金活'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/005.png','search':'康王'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/006.png','search':'葵花'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/007.png','search':'三九'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/008.png','search':'思密达'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/009.png','search':'万通'},
      //     {'url':'http://image.yabyy.com/home/floor/westernmedicine/010.png','search':'致君'}
      //   ]
      // },
      WesternMedicineLoading:false,
      WesternMedicineGoods:[],
    }
  },

  created() {
    if(localStorage.getItem('userInfo')){
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
    this.getArticleList()

    this.getPromotionGoods()
    // this.getChineseMedicineGoods()
    this.getWesternMedicineGoods()

  },
  computed: {
    ...mapGetters(['isLogin']),
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    webConfig() {
      return this.$store.state.settings.webConfig
    },
  },
  mounted() {
    // this.swiper.slideTo(3, 1000, false)
  },
  methods:{

    getPromotionGoods(){
      this.PromotionLoading = true
      getGoodsSearch({
        currentPage:1,
        pageSize:20,
        sidx:'id',
        //ids: this.PromotionFloor.map(arr => arr.id).join(',')
      }).then(res => {
        this.PromotionGoods = res.data.list
        this.PromotionGoods.forEach((i)=>{
          //只取第一个图片
          const image = JSON.parse(i.images)[0];
          if(image.imageUrl.indexOf("image.yabyy.com") == -1){
            // i.image = i.image
          }else{
            i.image = image.imageUrl
          }
        })
        this.PromotionLoading = false
      })
    },
    // getChineseMedicineGoods(){
    //   this.ChineseMedicineLoading = true
    //   getGoodsSearch({
    //     currentPage:1,
    //     pageSize:12,
    //     sidx:'id',
    //     ids: this.ChineseMedicineFloor.ids
    //   }).then(res => {
    //     this.ChineseMedicineGoods = res.data.list
    //     this.ChineseMedicineGoods.forEach((i)=>{
    //       //只取第一个图片
    //       const image = JSON.parse(i.images)[0];
    //       if(image.imageUrl.indexOf("image.yabyy.com") == -1){
    //         i.image = settings.AdminUrl + i.image
    //       }else{
    //         i.image = image.imageUrl
    //       }
    //     })
    //     this.ChineseMedicineLoading = false
    //   })
    // },
    getWesternMedicineGoods(){

      this.WesternMedicineLoading = true
      getGoodsSearch({
        currentPage:1,
        pageSize:12,
        sidx:'id',
        ids: this.WesternMedicineFloor.ids
      }).then(res => {
        this.WesternMedicineGoods = res.data.list
        this.WesternMedicineGoods.forEach((i)=>{
          //只取第一个图片
          const image = JSON.parse(i.images)[0];
          if(image.imageUrl.indexOf("image.yabyy.com") == -1){
            // i.image = i.image
          }else{
            i.image = image.imageUrl
          }
        })
        this.WesternMedicineLoading = false
      })
    },
    toBanner(url){
      if(url!=''){
        if(url.startsWith('http')){
          window.open(url, '_blank')
        }else{
          let routerJump = this.$router.resolve({ path: url})
          window.open(routerJump.href, '_blank')
        }

      }

    },
    getArticleList(){
      getArticlePaging({
        pageSize:20,
        sidx:'CreateTime',
        //sord:'desc',
        categoryId:'1498242619749306368'
      }).then(res => {
        this.articles = res.data.list
      })
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },
  }
}
</script>

<style scoped>
.swiper-button-prev{
  color: #ffffff;
}
.swiper-button-next{
  right: 50px;
  color: #ffffff;
}

.swiper-pagination-bullet-active{
  color: rgba(255,255,255,1) !important;
  background-color: rgba(255,255,255,1) !important;
}

.swiper-pagination-bullet{
  color: hsla(0,0%,100%,.3) !important;
  background-color: hsla(0,0%,100%,.3) !important;
}

.health .p-list li{
  height: 199px !important;
}
.health .floor-bd{
  height: 400px;
}

.health .FF-bd-more-04 .p-list li.left-child,
.health .FF-bd-more-04 .p-list li:nth-child(6n + 1){ margin-left:-1px; border-left-color:#dcdcdc; width:198px;}

.p-price a{
  color: red;
}

/*.vip-outcon .vip-con{*/
/*  height: 180px;*/
/*}*/
.vip-item .con{
  height: 210px;
}
.grayTheme{
  filter:progid:DXImageTransform.Microsoft.BasicImage(graysale=1) !important;
  -webkit-filter: grayscale(100%) !important;
}
</style>
